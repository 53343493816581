// Core
import React, { FC } from 'react'
// Components
import RelationsSelect from 'ui/relations-select'
// Hooks
import useFieldController from 'core/form/hooks/use-field-controller'
// Types
import { RelationsSelectProps } from 'ui/relations-select/relations-select'

type Props = {
  name: string
  skipSetValue?: boolean
} & Omit<RelationsSelectProps, 'value'>

const RelationsField: FC<Props> = (props) => {
  const { name, onChange, skipSetValue, ...rest } = props

  const [field, meta, { setValue }] = useFieldController(name)

  return (
    <RelationsSelect
      helperText={meta.errorMsg}
      error={meta.hasError}
      value={field.value}
      onChange={(val) => {
        if (!skipSetValue) setValue(val)
        onChange?.(val)
      }}
      onBlur={field.onBlur}
      {...rest}
    />
  )
}

export default RelationsField
