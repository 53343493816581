import React, { FC, useMemo, useState } from 'react'
import { BrokenImage, Folder } from '@material-ui/icons'
import { Box } from '@material-ui/core'
import { MediaListItem } from '../../types'

type Props = {
  media: MediaListItem
  size: 'list' | 'preview' | 'card'
}

export const MediaIcon: FC<Props> = ({ media, size }) => {
  const [imageLoadError, setImageLoadError] = useState(false)

  const icon = useMemo(() => {
    if (imageLoadError) {
      return <BrokenImage style={{ height: '70%', width: 'auto' }} color="action" />
    }

    if (media.type === 'media') {
      return (
        <img
          src={media.sourceUrl}
          alt={media.alt}
          onError={() => {
            setImageLoadError(true)
          }}
          style={{
            display: 'block',
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
        />
      )
    }

    if (media.type === 'folder') {
      return <Folder style={{ height: '70%', width: 'auto' }} color="action" />
    }

    return null
  }, [media, imageLoadError])

  const sizes = useMemo(() => {
    if (size === 'list') {
      return {
        width: 36,
        height: 36,
      }
    }

    if (size === 'preview') {
      return {
        width: 'auto',
        height: 260,
      }
    }

    return {
      width: 'auto',
      height: 'auto',
    }
  }, [size])

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      borderRadius="borderRadius"
      overflow="hidden"
      height={sizes.height}
      width={sizes.width}
      bgcolor="#F5F5F5"
      fontSize="200px"
    >
      {icon}
    </Box>
  )
}
