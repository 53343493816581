// Core
import React, { FC, ReactNode } from 'react'
import { CircularProgress, IconButton } from '@material-ui/core'
import { Delete, Edit, OpenInNew } from '@material-ui/icons'
// Hooks
import { useDeleteEntity } from 'modules/new-entity/hooks'

type Props = {
  valueData: any
  entityId: number
  onEdit?: (id: number) => void
  onDelete?: (id: number) => void
  canDelete?: boolean
  renderEditAction?: (valueData: any) => ReactNode
  refetch?: () => void
  getViewPath?: (id: number) => string
}

const Actions: FC<Props> = (props) => {
  const {
    valueData,
    entityId,
    onEdit,
    onDelete,
    canDelete,
    renderEditAction,
    refetch,
    getViewPath,
  } = props

  const { deleteEntityM, deleteEntity } = useDeleteEntity(entityId, {
    onSuccess: () => {
      refetch?.()

      if (onDelete) {
        onDelete(entityId)
      }
    },
  })

  const editHandler = (e: any) => {
    e.stopPropagation()

    if (onEdit) {
      onEdit(entityId)
    }
  }

  const deleteHandler = (e: any) => {
    e.stopPropagation()
    deleteEntity()
  }

  return (
    <div className="crud-actions">
      {deleteEntityM.isLoading ? (
        <CircularProgress size={15} />
      ) : (
        <>
          {onEdit && (
            <IconButton size="small" onClick={editHandler}>
              <Edit />
            </IconButton>
          )}
          {renderEditAction?.(valueData)}
          {getViewPath && (
            <IconButton
              component="a"
              href={getViewPath(entityId)}
              target="_blank"
              size="small"
              onClick={(e) => {
                e.stopPropagation()
              }}
            >
              <OpenInNew />
            </IconButton>
          )}
          {canDelete && (
            <IconButton size="small" onClick={deleteHandler}>
              <Delete />
            </IconButton>
          )}
        </>
      )}
    </div>
  )
}

export default Actions
