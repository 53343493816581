// Core
import React, { Fragment, ReactNode, useState, FC } from 'react'
import {
  Box,
  Divider,
  Drawer,
  Icon,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { Permissions, useAppContext } from 'core/app'
import { Link, useLocation } from 'react-router-dom'
import { getIdFromIri } from 'core/utils'
import { defaultThemeSizes } from 'core/themes/default/default'
import { httpService } from 'core/data'
import { useQuery } from 'react-query'
import { EntityType, HydraResponse } from 'core/types'
import { Skeleton } from '@material-ui/lab'

type Props = {}

type MenuItem = {
  title: string
  path?: string
  slug: string
  icon?: ReactNode
  children?: MenuItem[]
  resourceType?: string
  skipPermissions?: boolean
  isActive?: boolean
  isSingle?: boolean
}

const MenuData: MenuItem[] = [
  { title: 'Dashboard', path: '/', icon: <Icon className="icon-home1" />, slug: 'dashboard' },
  {
    title: 'Pages',
    icon: <Icon className="icon-file" />,
    slug: 'pages-group',
    children: [
      { title: 'Home page', slug: 'homepage', isSingle: true },
      { title: 'Static Pages', slug: 'page' },
      { title: 'Landing Pages', slug: 'landing_pages' },
      { title: 'Reviews', slug: 'reviews' },
      { title: 'Articles', slug: 'news' },
      { title: 'Authors', slug: 'authors' },
      { title: 'Categories', slug: 'category' },
      { title: 'Tags', slug: 'post-tag' },
      { title: '404', slug: '404-page', isSingle: true },
      { title: 'Interstitial page', slug: 'interstitial-pages' },
      { title: 'Templates', slug: 'templates', path: '/templates', skipPermissions: true },
    ],
  },
  {
    title: 'Brands',
    icon: <Icon className="icon-tag" />,
    slug: 'brands',
    children: [
      { title: 'Global Brands Ranking', slug: 'global_brands_ranking' },
      { title: 'Sportbook', slug: 'sportsbook-review' },
      { title: 'Casino', slug: 'cs-reviews' },
      { title: 'Poker', slug: 'poker-brand' },
      { title: 'Psychic', slug: 'psychics-review' },
      { title: 'Promotions', slug: 'bonuses' },
      { title: 'Affiliate Links	', slug: 'affiliate_link' },
    ],
  },
  {
    title: 'Products',
    icon: <Icon className="icon-shopping-bag" />,
    slug: 'products',
    children: [
      { title: 'Apps', slug: 'reviews-app' },
      { title: 'Games', slug: 'games' },
    ],
  },
  {
    title: 'Widgets',
    icon: <Icon className="icon-widget" />,
    slug: 'widgets-group',
    children: [
      { title: 'Global widgets', slug: 'widgets', path: '/widgets', resourceType: 'system' },
      { title: 'Global collection widget', slug: 'global_widget_collection' },
      {
        title: 'Translations',
        slug: 'translations',
        path: '/translations',
        resourceType: 'system',
      },
    ],
  },
  {
    title: 'Navigation',
    icon: <Icon className="icon-navigation" />,
    slug: 'navigation-group',
    children: [
      { title: 'Menus', slug: 'navigation', path: '/navigations' },
      { title: 'Language selector', slug: 'countries-languages' },
    ],
  },
  {
    title: 'Media',
    icon: <Icon className="icon-image" />,
    slug: 'media',
    resourceType: 'system',
    path: '/media',
  },
  {
    title: 'Settings',
    icon: <Icon className="icon-sliders" />,
    slug: 'site-settings',
    children: [
      { title: 'General', slug: 'site' },
      { title: 'SEO', slug: 'global-seo-settings', isSingle: true },
      { title: 'Archives', slug: 'global-news-settings', isSingle: true },
      { title: 'Affiliate links', slug: 'global_affiliate_link_settings', isSingle: true },
      { title: 'Header & Footer scripts', slug: 'header-footer-scripts', isSingle: true },
      { title: 'Redirects', slug: 'redirects', path: '/redirects', resourceType: 'system' },
      {
        title: 'Sitemap.xml',
        slug: 'sitemap_indices',
        path: '/sitemap-settings',
        resourceType: 'system',
      },
      { title: 'Hreflang connections', slug: 'href_lang_connections' },
      { title: 'Interstital page', slug: 'interstitial-page', isSingle: true },
      { title: 'Segments', slug: 'segments', path: '/segments', resourceType: 'system' },
    ],
  },
  {
    title: 'Admin',
    slug: 'administration-group',
    icon: <Icon className="icon-settings" />,
    children: [
      { title: 'Users', slug: 'users', path: '/users', resourceType: 'system' },
      { title: 'Sites', slug: 'sites', path: '/sites', resourceType: 'system' },
      { title: 'Networks', slug: 'networks', path: '/networks', resourceType: 'system' },
      {
        title: 'Entities',
        slug: 'entity_types',
        path: '/entities',
        resourceType: 'system',
      },
      {
        title: 'Entity type',
        slug: 'entity_types',
        path: '/entity_types',
        resourceType: 'system',
      },
      {
        title: 'Widget type',
        slug: 'entity_types',
        path: '/widget_types',
        resourceType: 'system',
      },
      {
        title: 'Layouts',
        slug: 'layouts',
        path: '/layouts',
        resourceType: 'system',
      },
    ],
  },
]

const checkIsActive = (menuItem: MenuItem, activePath: string) => {
  const { slug, path, children } = menuItem

  if (slug === 'administration-group' && activePath.includes('/entities')) {
    return false
  }

  return path === activePath || children?.some((subItem) => activePath.includes(subItem.path || ''))
}

const MainSidebar: FC<Props> = () => {
  const { pathname } = useLocation()

  const {
    actions: { userCan },
    resourceMapBySlug,
  } = useAppContext()

  const [openedSebMenu, setOpenedSubmenu] = useState<string | null>(null)

  const navigationQuery = useQuery('main-navigation', async () => {
    const typeIdsToFetch: string[] = []

    const filterMenuItems = () => {
      return MenuData.reduce<MenuItem[]>((data, menuItem) => {
        if (menuItem.slug === 'dashboard') {
          data.push(menuItem)
        }

        if (menuItem.children) {
          const filteredSubItems = menuItem.children.filter(
            (subItem) =>
              userCan(subItem.slug, Permissions.VIEW, subItem.resourceType || 'entityType') ||
              subItem.skipPermissions
          )

          if (filteredSubItems?.length > 0) {
            const subItemsWithPath: MenuItem[] = filteredSubItems?.map((subItem) => {
              const isSystem = subItem.resourceType === 'system'
              let path = '/404'
              let title = '404'

              if (subItem.path) {
                path = subItem.path
                title = subItem.title
              } else {
                const adminResource = resourceMapBySlug[subItem.slug]
                const resourceId = getIdFromIri(adminResource.iri)
                path = isSystem ? `${subItem.path}` : `/entities/${resourceId}`
                title = isSystem ? subItem.title : adminResource.name

                if (subItem.isSingle) {
                  typeIdsToFetch.push(resourceId)
                  path = resourceId
                }
              }

              return {
                ...subItem,
                path,
                title,
              }
            })

            const newItem: MenuItem = { ...menuItem, children: subItemsWithPath }

            data.push(newItem)
          }
        }

        if (menuItem.path && userCan(menuItem.slug, Permissions.VIEW, menuItem.resourceType)) {
          data.push(menuItem)
        }

        return data
      }, [])
    }

    const filteredMenuItems = filterMenuItems()

    const typesData = await httpService
      .get<HydraResponse<EntityType>>(`/entity_types`, {
        params: { id: typeIdsToFetch },
      })
      .then((res) => res.data['hydra:member'])

    filteredMenuItems.forEach((menuItem) => {
      menuItem.children?.forEach((subItem) => {
        if (subItem.isSingle) {
          const typeData = typesData.find((entityType) => +entityType.id === +subItem.path!)
          if (typeData?.singleEntity) {
            const entityId = getIdFromIri(typeData.singleEntity)
            subItem.path = `/entities/${subItem.path}/edit/${entityId}`
          }
        }
      })
    })

    return filteredMenuItems
  })

  const activeSubMenu = navigationQuery.data?.find((menuItem) => menuItem.slug === openedSebMenu)

  return (
    <>
      <Drawer
        variant="permanent"
        style={{ width: defaultThemeSizes.drawerWidthClose }}
        PaperProps={{ style: { zIndex: 1300 } }}
      >
        <List
          component={Box}
          display="flex"
          flexDirection="column"
          height="100%"
          disablePadding
          data-sidebar-opened={Boolean(openedSebMenu)}
        >
          {navigationQuery.isLoading &&
            Array.from(Array(13)).map((_, index) => (
              <Box
                key={index}
                px={1}
                my={1}
                textAlign="center"
                width={defaultThemeSizes.drawerWidthClose}
              >
                <Skeleton style={{ display: 'inline-flex' }} width={20} height={30} />
              </Box>
            ))}
          {navigationQuery.data?.map((menuItem, index) => {
            const { children, path, slug, title, icon } = menuItem
            const hasChildren = Boolean(children)
            const isLast = index === navigationQuery.data.length - 1

            const isActive = checkIsActive(menuItem, pathname)

            const clickHandler = () => {
              if (!hasChildren) {
                setOpenedSubmenu(null)
              } else {
                const value = openedSebMenu === slug ? null : slug
                setOpenedSubmenu(value)
              }
            }

            const itemProps = { component: path ? Link : 'li', to: path, onClick: clickHandler }

            return (
              <Fragment key={slug}>
                <Tooltip
                  title={''}
                  placement="right"
                  TransitionProps={{ style: { marginLeft: 3 } }}
                >
                  <ListItem
                    button
                    selected={isActive}
                    disableGutters
                    {...itemProps}
                    style={{
                      width: defaultThemeSizes.drawerWidthClose,
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginTop: isLast ? 'auto' : 0,
                    }}
                  >
                    <ListItemIcon style={{ marginRight: 0 }}>{icon}</ListItemIcon>
                    <ListItemText
                      primary={title}
                      primaryTypographyProps={{ variant: 'inherit' }}
                      style={{ fontSize: '0.6rem' }}
                    />
                  </ListItem>
                </Tooltip>
              </Fragment>
            )
          })}
        </List>
      </Drawer>
      <Drawer
        variant="persistent"
        open={Boolean(openedSebMenu)}
        onClose={() => setOpenedSubmenu(null)}
        ModalProps={{ hideBackdrop: true }}
        elevation={1}
        PaperProps={{
          style: {
            left: defaultThemeSizes.drawerWidthClose + 1,
            width: defaultThemeSizes.drawerWidth,
            transform: 'translateX(-212px) !important',
            zIndex: 1200,
          },
        }}
      >
        {activeSubMenu && (
          <List disablePadding>
            <Box
              component="li"
              px={2}
              height={defaultThemeSizes.pageHeaderHeight}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="subtitle1" style={{ fontWeight: 500 }}>
                {activeSubMenu.title}
              </Typography>
            </Box>
            <Divider />
            {activeSubMenu.children?.map((subMenuItem) => (
              <ListItem
                key={`${subMenuItem.slug}${subMenuItem.path}`}
                button
                selected={pathname.includes(subMenuItem.path || '')}
                component={Link}
                to={subMenuItem.path || '#'}
              >
                {subMenuItem.title}
              </ListItem>
            ))}
          </List>
        )}
      </Drawer>
    </>
  )
}

export default MainSidebar
