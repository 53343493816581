// Core
import React, { FC, ReactNode } from 'react'
import { makeStyles, MenuItem, MenuItemProps, Typography } from '@material-ui/core'
// Components
import SlugLabel from './slug-label'

const useStyles = makeStyles(() => ({
  option: {
    display: 'flex',
    justifyContent: 'space-between',
    '&:hover .crud-actions': {
      opacity: 1,
    },
    '& .crud-actions': {
      opacity: 0,
    },
  },
}))

type Props = {
  label: string
  urls?: { url: string }[]
  actionsHolder?: ReactNode
} & MenuItemProps

export const RelationsSelectItem: FC<Props> = ({
  label,
  urls,
  actionsHolder,
  ...menuItemProps
}) => {
  const classes = useStyles()

  return (
    <MenuItem className={classes.option} {...(menuItemProps as any)}>
      <Typography noWrap style={{ maxWidth: 250 }} component="div">
        {label}
        <SlugLabel entityUrls={urls || []} />
      </Typography>
      {actionsHolder}
    </MenuItem>
  )
}
