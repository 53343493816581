// Core
import React, { FC } from 'react'
// Components
import RelationsField from 'core/form/components/relations-field'
import { List } from './list'
// Hooks
import { useGetActiveSite } from 'modules/sites'
import { useLabelWithLocalization } from 'modules/new-entity/components/attributes-form/components/form-control/hooks/use-label-with-localization'
// Types
import { AttributeData } from 'modules/new-entity/types'

type Props = {
  attributeData: AttributeData
  name: string
}

const CrossSiteReference: FC<Props> = ({ name, attributeData }) => {
  const activeSite = useGetActiveSite()

  const { getLabelWithLocalization } = useLabelWithLocalization()

  return (
    <RelationsField
      multiple
      label={attributeData.name}
      valueField={'@id'}
      name={name}
      source={'entities'}
      listComponent={<List networkIri={activeSite?.network!} />}
      reqParams={{ network: activeSite?.network }}
      optionLabelField={getLabelWithLocalization}
      forceNewTabAction
    />
  )
}

export default CrossSiteReference
