import { useCallback, useReducer, useState } from 'react'
import { MediaListItem } from '../types'

type Props = {
  defaultFolder?: number | null
  defaultMedia?: number | null
}

type State = {
  selectedMedia?: number | null
  selectedFolder?: number | null
  folderPanelOpen?: boolean
  folderFilter?: number | null
  uploadFileOpen: boolean
}

type SelectMediaItemAction = {
  type: 'select_media_item'
  payload: MediaListItem
}

type ResetAction = { type: 'reset' }

type SetFolderAction = { type: 'set_folder_filter'; payload: number | null }

type OpenFolderPanelAction = { type: 'open_folder_panel'; payload: { id?: number } }

type OpenUploadAction = { type: 'open_upload' }

type Actions =
  | SelectMediaItemAction
  | ResetAction
  | SetFolderAction
  | OpenFolderPanelAction
  | OpenUploadAction

function stateReducer(state: State, action: Actions): State {
  const { type } = action

  const newState: State = {
    uploadFileOpen: false,
    folderFilter: state.folderFilter,
  }

  if (type === 'select_media_item') {
    const { payload } = action
    const stateField = payload.type === 'folder' ? 'selectedFolder' : 'selectedMedia'
    const isToggle = [state.selectedFolder, state.selectedMedia].includes(payload.id)

    if (payload.type === 'folder') {
      newState.folderPanelOpen = !isToggle
    }

    newState[stateField] = isToggle ? null : payload.id
  }

  if (type === 'set_folder_filter') {
    return {
      ...state,
      folderFilter: action.payload,
    }
  }

  if (type === 'open_folder_panel') {
    newState.folderPanelOpen = true
    if (action.payload.id) {
      newState.selectedFolder = action.payload.id
    }
    return newState
  }

  if (type === 'open_upload') {
    newState.uploadFileOpen = true
    return newState
  }

  if (type === 'reset') {
    return newState
  }

  return newState
}

export const useMediaManagerController = (props: Props = {}) => {
  const { defaultFolder, defaultMedia } = props
  const [search, setSearch] = useState('')

  const [state, dispatch] = useReducer(stateReducer, {
    uploadFileOpen: false,
    selectedMedia: defaultMedia,
    folderFilter: defaultFolder,
  })

  const selectMediaHandler = useCallback((mediaItem: MediaListItem) => {
    dispatch({ type: 'select_media_item', payload: mediaItem })
  }, [])

  const resetState = useCallback(() => dispatch({ type: 'reset' }), [])

  const setFolderFilter = useCallback(
    (payload: number | null) => dispatch({ type: 'set_folder_filter', payload }),
    []
  )

  const openFolderPanel = useCallback(
    (id?: number) => dispatch({ type: 'open_folder_panel', payload: { id } }),
    []
  )

  const openUpload = useCallback(() => {
    dispatch({ type: 'open_upload' })
  }, [])

  const doubleClickHandler = useCallback(
    (data: MediaListItem) => {
      if (data.type !== 'folder') return
      setFolderFilter(data.id)
    },
    [setFolderFilter]
  )

  return {
    ...state,
    resetState,
    setFolderFilter,
    selectMediaHandler,
    openFolderPanel,
    openUpload,
    search,
    setSearch,
    doubleClickHandler,
  }
}
