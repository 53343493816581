import React, { FC, useEffect, useMemo } from 'react'
import { Media, MediaListItem } from '../types'
import { useGetMediaFolders } from '../hooks/use-get-media-folders'
import { formatFileSize } from '../utils'
import { Box, Button } from '@material-ui/core'
import { Add } from '@material-ui/icons'
import { useQueryClient } from 'react-query'
import { MediaFolders } from '../components/media-folders'
import { UploadFiles } from '../components/upload-file'
import { FolderPreview } from '../components/folder-preview'
import { FilePreview } from '../components/file-preview'
import { GridColumn } from 'core/data/v2/data-grid/types'
import { DataGrid } from 'core/data/v2/data-grid'
import { useMediaManagerController } from './use-media-manager-controller'
import { Breadcrumbs } from '../components/breadcrumbs'
import { NameCell, LocationCell } from '../components/grid-columns'

const QUERY_KEY = 'media-manager-grid'

type Props = {
  maxHeight: string
  onSelectMedia?: (media: Media) => void
  selectedMedia?: number | null
}

export const MediaManager: FC<Props> = ({
  maxHeight,
  onSelectMedia,
  selectedMedia: selectedMediaProp,
}) => {
  const queryClient = useQueryClient()

  const { foldersData, foldersTreeData, isLoading, defaultFolder, refetchFolders } =
    useGetMediaFolders()

  const {
    selectedMedia,
    folderFilter,
    selectedFolder,
    folderPanelOpen,
    resetState,
    setFolderFilter,
    uploadFileOpen,
    selectMediaHandler,
    openFolderPanel,
    openUpload,
    search,
    setSearch,
    doubleClickHandler,
  } = useMediaManagerController({
    defaultFolder,
    defaultMedia: selectedMediaProp,
  })

  useEffect(() => {
    setFolderFilter(defaultFolder)
  }, [defaultFolder, setFolderFilter])

  const rightSidebar = useMemo(() => {
    if (selectedMedia) {
      return (
        <FilePreview
          mediaId={selectedMedia}
          onClose={resetState}
          onDeleteSuccess={() => {
            resetState()
            queryClient.invalidateQueries(QUERY_KEY)
          }}
          onUpdateSuccess={() => {
            queryClient.invalidateQueries(QUERY_KEY)
          }}
          onSelectMedia={onSelectMedia}
        />
      )
    }

    if (uploadFileOpen && folderFilter) {
      return (
        <UploadFiles
          defaultFolder={folderFilter}
          onFileUpload={() => queryClient.invalidateQueries(QUERY_KEY)}
          onClose={resetState}
        />
      )
    }

    if (folderPanelOpen && typeof folderFilter === 'number') {
      return (
        <FolderPreview
          defaultFolder={folderFilter}
          folderId={selectedFolder}
          onClose={resetState}
          onEditSuccess={() => {
            refetchFolders()
            queryClient.invalidateQueries(QUERY_KEY)
          }}
          onDeleteSuccess={() => {
            resetState()
            refetchFolders()
            setFolderFilter(defaultFolder)
            queryClient.invalidateQueries(QUERY_KEY)
          }}
        />
      )
    }

    return null
  }, [
    defaultFolder,
    folderFilter,
    folderPanelOpen,
    onSelectMedia,
    queryClient,
    refetchFolders,
    resetState,
    selectedFolder,
    selectedMedia,
    setFolderFilter,
    uploadFileOpen,
  ])

  const gridColumns = useMemo(() => {
    let columns: GridColumn<MediaListItem>[] = [
      {
        field: 'name',
        type: 'custom',
        cellRenderer: ({ rowData }) => (
          <NameCell onFolderTitleClick={setFolderFilter} media={rowData} />
        ),
      },
      {
        field: 'size',
        type: 'custom',
        cellRenderer: ({ rowData }) => formatFileSize(rowData.size),
      },
      {
        field: 'createdBy',
        type: 'relation',
        title: 'Author',
        resource: 'users',
        titleField: (user) => `${user.firstName} ${user.lastName}`,
      },
      { field: 'createdAt', type: 'date', title: 'Created at' },
      { field: 'updatedAt', type: 'date', title: 'Updated at' },
    ]

    if (search) {
      columns = columns.toSpliced(1, 0, {
        field: 'parent',
        type: 'custom',
        cellRenderer: ({ rowData }) => (
          <LocationCell media={rowData} foldersData={foldersData || []} />
        ),
        title: 'Location',
      })
    }

    return columns
  }, [search, setFolderFilter, foldersData])

  return (
    <DataGrid
      maxHeight={maxHeight}
      queryKey={QUERY_KEY}
      resource="media_advanceds"
      columns={gridColumns}
      filters={{ folder: folderFilter || null }}
      queryOptions={{ enabled: typeof folderFilter === 'number' }}
      selectedRow={selectedMedia || selectedFolder}
      onRowSelect={selectMediaHandler}
      onDoubleClickSelect={doubleClickHandler}
      onSearchChange={setSearch}
      leftSidebar={
        <MediaFolders
          selected={folderFilter}
          onSelect={setFolderFilter}
          data={foldersTreeData || []}
          isLoading={isLoading}
          onEdit={(id) => openFolderPanel(id)}
        />
      }
      rightSidebar={rightSidebar}
      breadcrumbs={
        <Breadcrumbs
          folder={folderFilter || 0}
          foldersData={foldersData || []}
          onSelect={setFolderFilter}
        />
      }
      actionsHolder={
        <Box style={{ display: 'flex', gap: 16 }}>
          <Button
            variant="outlined"
            color="primary"
            startIcon={<Add />}
            onClick={() => openFolderPanel()}
            disabled={!folderFilter}
          >
            Add folder
          </Button>
          <Button
            variant="contained"
            color="primary"
            startIcon={<Add />}
            onClick={() => openUpload()}
            disabled={!folderFilter}
          >
            Add file
          </Button>
        </Box>
      }
    />
  )
}
