import React, { ReactNode, FC } from 'react'
import { Box, Typography } from '@material-ui/core'

type DetailItemProps = {
  title: string
  value: ReactNode
}

export const DetailItem: FC<DetailItemProps> = ({ title, value }) => {
  return (
    <Box>
      <Typography noWrap component="div" color="textSecondary" variant="body2">
        <b style={{ marginRight: 4 }}>{title}:</b>
        {value}
      </Typography>
    </Box>
  )
}
