import { FormAttribute } from './types'
// eslint-disable-next-line import/no-cycle
import { ValuesGenerator } from './values-generator'
import { withWrapValue } from 'core/utils'
import { isNullable, isEmptyString } from 'core/utils'
// Types
import { AttributeTypesList, OneOfAttributeTypes } from 'common/types'

enum ValuesTypes {
  simple = 'simple',
  nullable = 'nullable',
  localized = 'localized',
}

type OneOfValuesType = keyof typeof ValuesTypes

const valuesTypesList: any = {
  [ValuesTypes.simple]: [
    AttributeTypesList.string,
    AttributeTypesList.slug,
    AttributeTypesList.text,
    AttributeTypesList.text_editor,
    AttributeTypesList.boolean,
  ],
  [ValuesTypes.nullable]: [
    AttributeTypesList.date_time,
    AttributeTypesList.integer,
    AttributeTypesList.decimal,
    AttributeTypesList.attachment,
    AttributeTypesList.image,
    AttributeTypesList.reference_one_to_one,
    AttributeTypesList.reference_many_to_one,
    AttributeTypesList.reference_many_to_one_multiple_types,
    AttributeTypesList.reference_many_to_one_hierarchical,
    AttributeTypesList.reference_many_to_one_multiple_entity_types,
    AttributeTypesList.reference_many_to_many_multiple_types_reversed,
    AttributeTypesList.select,
  ],
  [ValuesTypes.localized]: [
    AttributeTypesList.string_l10n,
    AttributeTypesList.text_l10n,
    AttributeTypesList.text_editor_l10n,
  ],
}

export class AttributeValue {
  private value: any

  constructor(
    private attribute: FormAttribute,
    private values: any,
    private order?: number,
    private repeatKey: string = 'setRepeats', // Default key for Entity
    private skipIds: boolean = false
  ) {
    this.repeatKey = repeatKey

    try {
      const finalType = this.getGenerationType(attribute.attribute.attributeType.type)
      /**
       * call special handler */
      this[finalType]()
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(
        `Method in 'AttributeValue' class for resolve "${attribute.attribute.attributeType.type}" value doesn't exist
         With error: ${e.message}`
      )
    }
  }

  private getGenerationType(attributeType: OneOfAttributeTypes) {
    const typeKeys = Object.keys(valuesTypesList) as OneOfValuesType[]
    for (const typeKey of typeKeys) {
      if (valuesTypesList[typeKey].includes(attributeType)) {
        return typeKey
      }
    }
    return attributeType as OneOfValuesType
  }

  getValue() {
    return this.value
  }

  /**
   * Rewrited
   * handlers
   */
  private [ValuesTypes.simple]() {
    const { attribute } = this.attribute
    const attributeIri = attribute['@id']
    const { value, id } = this.values[attributeIri]

    if (
      //
      isNullable(value) ||
      isEmptyString(value)
    ) {
      this.value = { attribute: attributeIri, ...withWrapValue(null, id, this.skipIds) }
    } else {
      this.value = { attribute: attributeIri, ...withWrapValue(value, id, this.skipIds) }
    }
  }

  private [ValuesTypes.nullable]() {
    const { attribute } = this.attribute
    const attributeIri = attribute['@id']
    const { value, id } = this.values[attributeIri]

    if (
      //
      isNullable(value) ||
      isEmptyString(value)
    ) {
      this.value = { attribute: attributeIri, ...withWrapValue(null, id, this.skipIds) }
    } else {
      this.value = { attribute: attributeIri, ...withWrapValue(value, id, this.skipIds) }
    }
  }

  private [ValuesTypes.localized]() {
    const { attribute } = this.attribute
    const attributeIri = attribute['@id']
    const localizedVal: any[] = []

    Object.keys(this.values.translations).forEach((locale) => {
      const values = this.values.translations[locale]
      const attrIris = Object.keys(values).filter((iri) => iri === attributeIri)

      attrIris.forEach((attrIri: any) => {
        const { value, id } = values[attrIri]

        localizedVal.push({
          attribute: attrIri,
          locale: `api/localizations/${locale}`,
          ...withWrapValue(value, id, this.skipIds),
        })
      })
    })

    this.value = localizedVal
  }

  /**
   * Uniq
   * handlers
   */

  private [AttributeTypesList.reference_many_to_many]() {
    /**
     * TODO
     * This is rewrited attr (not used id) */

    const { attribute } = this.attribute
    const attributeIri = attribute['@id']
    const { value } = this.values[attributeIri] as { value: []; id?: number }

    if (value.length === 0) {
      this.value = { attribute: attributeIri, ...withWrapValue(null) }
    } else {
      this.value = value.map((value, i) => ({
        attribute: attributeIri,
        sortOrder: i,
        ...withWrapValue(value),
      }))
    }
  }

  private [AttributeTypesList.reference_many_to_many_multiple_types]() {
    /**
     * TODO
     * This is rewrited attr (not used id) */

    const { attribute } = this.attribute
    const attributeIri = attribute['@id']
    const { value } = this.values[attributeIri] as { value: []; id?: number }

    if (!value || value.length === 0) {
      this.value = { attribute: attributeIri, ...withWrapValue(null) }
    } else {
      this.value = value.map((value) => ({
        attribute: attributeIri,
        ...withWrapValue(value),
      }))
    }
  }

  private [AttributeTypesList.multi_select]() {
    /**
     * TODO
     * This is rewrited attr (not used id) */

    const { attribute } = this.attribute
    const attributeIri = attribute['@id']
    const { value } = this.values[attributeIri] as { value: []; id?: number }

    if (value.length === 0) {
      this.value = { attribute: attributeIri, ...withWrapValue(null) }
    } else {
      this.value = value.map((value) => ({
        attribute: attributeIri,
        ...withWrapValue(value),
      }))
    }
  }

  private [AttributeTypesList.repeater]() {
    const {
      attribute: { setAttributes },
      attribute,
    } = this.attribute
    const attrIri = attribute['@id']
    const valuesGenerator = new ValuesGenerator(
      setAttributes || [],
      this.values,
      this.repeatKey,
      this.skipIds
    )

    this.value = {
      sortOrder: this.order,
      set: attrIri,
      ...valuesGenerator.getData(),
      /**
       * must have
       * with repeat attr ID */
      // ...(() => (this.values?.id ? { id: this.values?.id } : null))(),
      ...(this.values?.id && !this.skipIds ? { id: this.values?.id } : null),
    }
  }
}
