// Core
import React, { FC, useMemo } from 'react'
import { useQuery } from 'react-query'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { DataControllerProps, httpService } from 'core/data'
// Components
import { Grid } from '@material-ui/core'
import { TextField, CheckboxField } from 'core/form'
import { ReferenceInput, SelectRef } from 'core/form'
import RelationsField from 'core/form/components/relations-field'
import DefaultLocalization from './components/default-localization'
// Utils
import moment from 'moment-timezone'

const timeZones = moment.tz.names()

const offsetTmz: any[] = []

const CrudForm: FC<DataControllerProps> = (props) => {
  const { t } = useTranslation()

  const { data, isLoading, isError } = useQuery(
    'entity-type-site',
    async () => {
      const res = await httpService.get<any>('/entity_types', { params: { slug: 'site' } })
      return res.data['hydra:member']
    },
    {
      onError: (e: any) => {
        if (e.response.status) {
          toast.error(`${e.response.status}-${e.message}`)
        } else {
          console.error(e)
        }
      },
    }
  )
  useMemo(() => {
    return timeZones.forEach((item, index) => {
      offsetTmz.push({
        name: `UTC ${moment.tz(item).format('Z')}: ${timeZones[index]}`,
        '@id': `${timeZones[index]}`,
      })
    })
  }, [])

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TextField name={'name'} label={t('labels.site-name')} />
      </Grid>
      <Grid item xs={12}>
        <TextField name="url" label={t('labels.url')} />
      </Grid>
      <Grid item xs={12}>
        <TextField name="dateFormat" label={t('labels.d-format')} />
      </Grid>
      <Grid item xs={12}>
        <TextField name="timeFormat" label={t('labels.t-format')} />
      </Grid>
      <Grid item xs={12}>
        <ReferenceInput source="timezone" choices={offsetTmz} label={t('labels.timezone')}>
          <SelectRef optionText="name" />
        </ReferenceInput>
      </Grid>

      {!isLoading && !isError && (
        <Grid item xs={12}>
          <RelationsField
            valueField={'@id'}
            name={'entity'}
            source={'entities'}
            reqParams={{ entityType: data[0] ? data[0]['@id'] : [] }}
            label={t('select.entity')}
            defaultValue={null}
          />
        </Grid>
      )}

      <Grid item xs={12}>
        <RelationsField
          valueField={'@id'}
          name={'network'}
          source={'networks'}
          label={t('labels.network')}
        />
      </Grid>
      <Grid item xs={12}>
        <DefaultLocalization />
      </Grid>
      <Grid item xs={12}>
        <RelationsField
          multiple
          valueField={'@id'}
          idsFilterName="code"
          name={'additionalLocalizations'}
          source={'localizations'}
          label={t('labels.additionalLocalizations')}
          optionLabelField={(data: any) => `${data.code} ${data.name}`}
        />
      </Grid>
      <Grid item xs={12}>
        <CheckboxField name="noindex" label={t('labels.no-index')} />
      </Grid>
      <Grid item xs={12}>
        <TextField multiline name={'robot'} label="Custom robots.txt" />
      </Grid>
    </Grid>
  )
}

export default CrudForm
