import React, { FC, useMemo } from 'react'
import { Chip, makeStyles } from '@material-ui/core'
import { OpenInNew } from '@material-ui/icons'
import clsx from 'clsx'
import { getIdFromIri } from 'core/utils'
import { EntityListItem } from 'modules/eav/types'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 2,
    maxWidth: '100%',
  },
  chipLabel: {
    display: 'flex',
    alignItems: 'center',
  },
  label: {
    marginRight: theme.spacing(1.5),
  },
  withNewTab: {
    paddingRight: 0,
  },
  link: {
    display: 'inline-flex',
    marginRight: theme.spacing(0.5),
  },
}))

type Props = {
  label: string
  title?: string
  openInNewTabAction?: boolean
  entityData?: EntityListItem
  onDelete?: () => void
}

export const ValueChip: FC<Props> = ({
  label,
  openInNewTabAction,
  onDelete,
  title,
  entityData,
}) => {
  const classes = useStyles()

  const newTabPath = useMemo(() => {
    if (!openInNewTabAction || !entityData) return null

    const siteId = getIdFromIri(entityData.site)
    const typeId = getIdFromIri(entityData.entityType)

    return `/${siteId}/entities/${typeId}/edit/${entityData.id}`
  }, [entityData, openInNewTabAction])

  return (
    <Chip
      className={classes.root}
      classes={{
        label: clsx(classes.chipLabel, { [classes.withNewTab]: openInNewTabAction }),
      }}
      label={
        <>
          <span className={classes.label}>{label}</span>
          {newTabPath && (
            <a href={newTabPath} className={classes.link} target="_blank" rel="noreferrer">
              <OpenInNew className="MuiChip-deleteIcon" />
            </a>
          )}
        </>
      }
      onDelete={onDelete}
      title={title}
    />
  )
}
